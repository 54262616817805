<template>
  <div class="modify">
    <van-nav-bar
        title="编辑"
        left-text="返回"
        left-arrow
        fixed
        @click-left="onClickLeft"
    />
    <scroll class="product_scroll" :onLoadMore="onLoadMore">
      <van-form @submit="onSubmit">
        <van-field
            class="modify_item"
            label="工序编号"
            v-model="wpCode"
            required
            type="text"
            placeholder="请输入"
            input-align="center"
        />
        <van-field
            class="modify_item"
            label="工序名称"
            required
            v-model="wpName"
            type="text"
            placeholder="请输入"
            input-align="center"
        />
        <div class="work_number" @click="jurisdictionShow = true">
          <div class="work_number_name power">
            <div><span class="icon icon-bitian"></span>报工权限</div>
            <div class="rw_prower_text">
              <div
                  class="rw_prower_text_item"
                  v-for="item in rwPowerText"
                  :key="item"
              >
                {{ item }},
              </div>
            </div>
          </div>
          <div class="work_number_r">
            <div class="icon icon-ic_arrow_r"></div>
          </div>
        </div>
        <van-field
            class="modify_item"
            label="报工数配比"
            v-model="rwProportion"
            type="text"
            required
            placeholder="请输入"
            input-align="center"
        />
        <div class="work_number" @click="defectiveShow = true">
          <div class="work_number_name power">
            <div><span class="icon icon-bitian"></span>不良品列表</div>
            <div>
              <div
                  class="rw_prower_text"
                  v-for="item in defectiveItemText"
                  :key="item"
              >
                {{ item }},
              </div>
            </div>
          </div>
          <div class="work_number_r">
            <div class="icon icon-ic_arrow_r"></div>
          </div>
        </div>
        <customise ref="customise" :defType="defType"></customise>
        <div style="margin: 16px" class="submit">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </scroll>
    <van-popup
        v-model="jurisdictionShow"
        position="bottom"
        round
        :style="{ height: '30%' }"
        class="jurisdiction"
        :close-on-click-overlay="false"
    >
      <div class="jurisdiction_headle">
        <div @click="jurisdictionShow = false">取消</div>
        <div
            class="jurisdiction_headle_define"
            @click="jurisdictionShow = false"
        >
          确定
        </div>
      </div>
      <van-checkbox-group v-model="rwPowerText">
        <van-checkbox
            :name="item.nickname"
            v-for="item in SysUserList"
            :key="item.id"
        >{{ item.nickname }}
        </van-checkbox
        >
      </van-checkbox-group>
    </van-popup>
    <van-popup
        v-model="defectiveShow"
        position="bottom"
        round
        :style="{ height: '30%' }"
        class="jurisdiction"
        :close-on-click-overlay="false"
    >
      <div class="jurisdiction_headle">
        <div @click="defectiveShow = false">取消</div>
        <div class="jurisdiction_headle_define" @click="defectiveShow = false">
          确定
        </div>
      </div>
      <van-checkbox-group v-model="defectiveItemText">
        <van-checkbox
            :name="item.defectiveItems"
            v-for="item in DefectiveItemList"
            :key="item.id"
        >{{ item.defectiveItems }}
        </van-checkbox
        >
      </van-checkbox-group>
    </van-popup>
  </div>
</template>
<script>
import scroll from "../../components/scroll/index";
import customise from "@/components/customise/index";
import {mapGetters} from "vuex";
import {ChangePageTitle} from "../../common/js/upTitle";
import {
  getEwoDefectiveItemList,
  getSysUserList,
  getProcedureInfo,
  onSubmitProcedure,
  upadtaProcedure,
} from "@/api/procedure";

export default {
  computed: {
    ...mapGetters(["departmentCode"]),
  },
  data() {
    return {
      defType: 2,
      modifyId: "", //工序id
      wpCode: "", //工序编号
      wpName: "", //工序名称
      rwProportion: "", //报工比例
      rwPowerText: [], //报工权限
      rwPowerList: [], //报工权限id
      defectiveItemText: [], //不良品列表
      changeShow: false, //采集项文本是否显示
      jurisdictionShow: false, //权限所有人弹窗
      defectiveShow: false, //不良品弹窗
      SysUserList: [], //报工权限所有
      DefectiveItemList: [], //所有不良品项
      moderText: [], //definitionType == 1
      moderNumber: [], //definitionType == 2
      show: false,
      dataShow: false,
      colorListShow: false, //颜色选择
      moderTime: [], //definitionType == 3
      moderImg: [], //definitionType == 4
      moderRa: [], //definitionType == 5
      moderCh: [], //definitionType == 6
    };
  },
  created() {
    ChangePageTitle("编辑");
    this.getSysUserList();
    this.getEwoDefectiveItemList();
    // console.log(window.localStorage.getItem('modifyId'))
    if (window.localStorage.getItem('modifyId') != null) {
      this.getProcedureInfo(window.localStorage.getItem('modifyId'));
      this.modifyId = window.localStorage.getItem('modifyId');
    }
  },
  methods: {
    onClickLeft() {
      this.$router.push("/procedure");
    },
    //获取所有不良品项
    async getEwoDefectiveItemList() {
      const departmentCode = this["departmentCode"];
      const res = await getEwoDefectiveItemList(departmentCode);
      res.forEach((v) => {
        v.defectiveItems = v.defectiveCode + "|" + v.defectiveName;
      });
      this.DefectiveItemList = res;
    },
    //获取报工权限所有人
    async getSysUserList() {
      var params = {
        labelCode: "generate_person",
        departmentCode: this["departmentCode"],
      };
      const res = await getSysUserList(params);
      this.SysUserList = res;
    },
    async onSubmit() {
      var _that = this;
      if (_that.wpCode == "") {
        _that.$toast("请输入工单编号");
        return;
      }
      if (_that.wpName == "") {
        _that.$toast("请输入工序名称");
        return;
      }
      if (_that.rwPowerText.length == 0) {
        _that.$toast("请选择报工权限");
        return;
      }
      if (_that.rwProportion == "") {
        _that.$toast("请输入报工数配比");
        return;
      }
      if (_that.defectiveItemText.length == 0) {
        _that.$toast("请选择不良品列表");
        return;
      }
      var rwPowerId = [];
      for (var i = 0; i < _that.rwPowerText.length + 1; i++) {
        !(function (ii) {
          const index = _that.SysUserList.findIndex(
              (v) => v.nickname == _that.rwPowerText[ii]
          );
          if (index != -1) {
            rwPowerId.push(_that.SysUserList[index].id);
          }
        })(i);
      }
      var defectiveItemsId = [];
      for (var i = 0; i < _that.defectiveItemText.length + 1; i++) {
        !(function (ii) {
          const index = _that.DefectiveItemList.findIndex(
              (v) => v.defectiveItems == _that.defectiveItemText[ii]
          );
          if (index != -1) {
            defectiveItemsId.push(_that.DefectiveItemList[index].id);
          }
        })(i);
      }
      const res = await this.$refs.customise.onModulSubmit();
      var params1 = {
        id: _that.modifyId == "" ? "" : _that.modifyId,
        wpCode: _that.wpCode,
        wpName: _that.wpName,
        departmentCode: this["departmentCode"],
        rwProportion: _that.rwProportion,
        rwPower: rwPowerId.toString(),
        defectiveItems: defectiveItemsId.toString(),
        definitionProperties: res,
      };
      if (_that.modifyId == "") {
        try {
          await onSubmitProcedure(params1);
          _that.$toast("添加成功");
          _that.$router.push("/procedure");
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          await upadtaProcedure(params1);
          _that.$toast("更新成功");
          _that.$router.push("/procedure");
        } catch (err) {
          console.log(err);
        }
      }
    },
    onLoadMore(done) {
      setTimeout(() => {
        if (!this.enableLoadMore) {
          return;
        }
        this.page = this.page + 1;
        this.getListData();
        done();
      }, 200);
    },
    async getProcedureInfo(id) {
      const res = await getProcedureInfo(id);
      this.wpCode = res.wpCode;
      this.wpName = res.wpName;
      this.rwProportion = res.rwProportion;
      this.rwPowerText = res.rwPowerText.split(",");
      this.defectiveItemText = res.defectiveItemText.split(",");
      this.rwPowerList = res.rwPowerList;
    },
  },
  components: {
    scroll,
    customise,
  },
};
</script>
<style lang="less" scoped>
.modify {
  width: 100vw;
  height: 100vh;

  .product_scroll {
    padding-top: 85px;
  }

  .van-cell {
    width: 95%;
    margin: auto;
    margin-top: 30px;
    border-radius: 5px;
  }

  .work_number {
    width: 95%;
    margin: auto;
    margin-top: 30px;
    box-sizing: border-box;
    padding: 17px 13px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 30px;
    color: #646566;

    .icon-bitian {
      color: red;
      font-size: 30px;
    }

    .rw_prower_text {
      display: flex;
      align-items: center;
      color: blue;
    }

    .icon-ic_arrow_r {
      font-size: 40px;
    }
  }

  .jurisdiction {
    padding: 20px;
    font-size: 30px;

    .jurisdiction_headle {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .van-checkbox-group {
      margin-top: 30px;

      .van-checkbox {
        margin-top: 10px;
      }
    }
  }
}
</style>
