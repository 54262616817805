import request  from "@/utils/request";
export function getEwoProcedureList(data){
    return request({
        url:'/ewo/ewoProcedure/getPageList',
        method:'post',
        data
    })
}
export function getEwoDefectiveItemList(departmentCode){
    return request({
        url:`/ewo/ewoDefectiveItem/getEwoDefectiveItemListByDepartmentCode/${departmentCode}`,
        method:'get'
    })
}
export function getSysUserList(data){
    return request({
        url:'/sysUser/getSysUserListByLabel',
        method:'post',
        data
    })
}

export function getProcedureInfo(id){
    return request({
        url:`/ewo/ewoProcedure/info/${id}`
    })
}
export function onSubmitProcedure(data){
    return request({
        url:'/ewo/ewoProcedure/add',
        method:'post',
        data
    })
}
export function upadtaProcedure(data){
    return request({
        url:'/ewo/ewoProcedure/update',
        method:'post',
        data
    })
}
export function getEwoMouldList(data){
    return request({
        url:'/ewo/ewoCraft/getPageList',
        method:'post',
        data
    })
}
export function onSubmitCraftAdd(data){
    return request({
        url:'/ewo/ewoCraft/add',
        method:'post',
        data
    })
}